@import "index", "font", "basic", "layout", "classes";

html {
  background: lighten($dark, 70%);
  padding: 0 calc(38% - 21em); }

body {
  box-shadow: 0 0 .3em lighten($dark, 65%); }

body > header, body > footer {
  background: lighten($dark, 5%);
  color: $light; }

@media (min-width: 32em) {
  body > header, body > article, body > footer {
    padding: 1.65em calc(22% - 6em); } }

@media (prefers-color-scheme: dark) {
  body, body > header, body > footer {
    box-shadow: 0 0 0 1px lighten($dark, 8%); }
  html, body > header, body > footer {
    background: $dark; } }
